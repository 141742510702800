import { AUTO_EMBED_TABS } from '../../constants/yourSite'

export type SliceState = {
  selectedPlaylistId: string
  playlistVideos: globalLib.IVideo[]
  playlistVideosStatus: 'loading' | 'success' | 'error'
  isEnableBannerVisible: boolean
  isHelpBannerVisible: boolean
  embedConfig: AutoEmbedConfiguration[]
  integrationsActiveTab: string
  embedStatus: {
    loading: boolean
    status: 'enabled' | 'disabled' | 'error' | null
  }
  selectedStoreIdByBusinessId: {
    [businessId: string]: string
  }
  pdpGalleryConfig: YourSite.PDPGallery
  shopifyAPIScopeValidation: {
    loading: boolean
    status: 'success' | 'error' | null
    message: string
  }
}

export const initialState: SliceState = {
  selectedPlaylistId: null,
  playlistVideos: [],
  playlistVideosStatus: 'success',
  isEnableBannerVisible: true,
  isHelpBannerVisible: false,
  embedConfig: null,
  integrationsActiveTab: AUTO_EMBED_TABS.HOME_PAGE,
  embedStatus: {
    loading: false,
    status: null
  },
  selectedStoreIdByBusinessId: {},
  pdpGalleryConfig: null,
  shopifyAPIScopeValidation: {
    loading: false,
    status: null,
    message: null
  }
}
