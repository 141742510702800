import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import api from '@src/common/utils/api'

import { SLICE_NAME } from './constants'
import { SliceState } from './initState'

export const shopify_fetchScopeValidation = createAsyncThunk(
  `${SLICE_NAME}/fetchScopeValidation`,
  async (params: {
    storeUrl: string
  }): Promise<{
    message: string
    status: 'success' | 'error'
  }> => {
    const { data } = await api.get(
      `/shopify/api/scope_validation?myshopify_domain=${params.storeUrl}`
    )

    return data
  }
)

export const shopifyBuilder = (
  builder: ActionReducerMapBuilder<SliceState>
): void => {
  builder
    .addCase(shopify_fetchScopeValidation.pending, (state) => {
      state.shopifyAPIScopeValidation.loading = true
    })
    .addCase(shopify_fetchScopeValidation.fulfilled, (state, action) => {
      state.shopifyAPIScopeValidation.loading = false
      state.shopifyAPIScopeValidation.status = action.payload.status
      state.shopifyAPIScopeValidation.message = action.payload.message
    })
    .addCase(shopify_fetchScopeValidation.rejected, (state) => {
      state.shopifyAPIScopeValidation.loading = false
      state.shopifyAPIScopeValidation.status = 'error'
      state.shopifyAPIScopeValidation.message =
        'Error fetching scope validation'
    })
}
