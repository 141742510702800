import React, { FC, useMemo } from 'react'

import { InfoCircleOutlined } from '@ant-design/icons'
import css from '@emotion/css'
import { FWButton } from '@src/components'
import { Box, Flex } from '@src/components/EmotionLayout'
import { StatusTag } from '@src/components/StatusTag'
import { useNabooFeatureFlag } from '@src/hooks'
import {
  addRemoveProductColSize,
  checkboxColSize,
  deleteColSize,
  expandSelectVariantColSize,
  highlightColSize,
  importColSize,
  productAvailableColSize,
  productDropColSize,
  productNameColSize,
  productPriceColSize,
  productThumbnailColSize,
  shouldRenderAddRemoveProductCol,
  shouldRenderCheckboxCol,
  shouldRenderDeleteCol,
  shouldRenderExpandSelectVariantCol,
  shouldRenderHighlightCol,
  shouldRenderImportCol,
  shouldRenderProductAvailableCol,
  shouldRenderProductDropCol
} from '@src/pages/channel/Products/helpers'
import theme from '@src/styles/theme'
import { extractQueryParams } from '@src/utils/url'
import { Checkbox, Col, Dropdown, Menu, Row, Switch, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'

import { ProductThumbnail } from './ProductThumbnail'

export const ROW_HEIGHT = '100px'

type ProductProps = {
  product: globalLib.Product
  variant?: globalLib.ProductUnit
  onAdd: (product: globalLib.Product) => void
  onRemove: (product: globalLib.Product) => void
  setAvailability: (product: globalLib.Product) => void
  disabled: boolean
  highlighted: boolean
  onHighlight: (product: globalLib.Product) => void
  selectType: string
  selected: boolean
  onDelete: (productId: string) => void
  identifier: string
  onCreate: (product: globalLib.Product) => void
  highlightedIndex: number
  margin: boolean
  collapseUnit?: (product: globalLib.Product) => void
  expandProducts?: string[]
  indeterminate?: boolean
  onToggleProductDrop?: (product: globalLib.Product, isHidden: boolean) => void
  urlToImageMap?: Record<string, globalLib.Image>
  images: globalLib.Image[]
}

const Product: FC<ProductProps> = ({
  product,
  variant,
  onAdd,
  onRemove,
  setAvailability,
  disabled,
  highlighted,
  onHighlight,
  selectType,
  selected,
  onDelete,
  indeterminate,
  identifier,
  margin,
  onCreate,
  collapseUnit,
  expandProducts,
  highlightedIndex,
  onToggleProductDrop,
  urlToImageMap,
  images
}) => {
  const { t } = useTranslation()
  const nff = useNabooFeatureFlag()
  const isUnavailable =
    setAvailability && product.available_for_live_stream_replay === false
  const addedForReplay = product.added_in_live_stream_replay === true
  const unavailableStyle = isUnavailable ? { opacity: 0.5 } : null

  const productUnits = product?.product_units

  const firstProductUnit = useMemo(
    () =>
      productUnits?.length
        ? productUnits
            .slice()
            .sort((a, b) => a.unit_position - b.unit_position)[0]
        : null,
    [productUnits]
  )

  const highlightStyle = {
    background: '#F9F9F9',
    cursor: 'pointer'
  }

  const urlParams = extractQueryParams(
    variant ? variant.unit_url : firstProductUnit?.unit_url
  )

  return (
    <Row
      css={css`
        width: 100%;
      `}
      style={
        highlighted
          ? { paddingBottom: margin ? '10px' : 0, ...highlightStyle }
          : { paddingBottom: margin ? '10px' : 0 }
      }
      onClick={
        onHighlight && (!disabled || highlightedIndex)
          ? () =>
              onHighlight(
                variant
                  ? `${product[identifier]}-${variant.id}`
                  : product[identifier]
              )
          : null
      }
      gutter={[4, 6]}
      data-testid={`product-${product[identifier]}`}
    >
      {shouldRenderCheckboxCol(onAdd, onRemove, selectType) ? (
        <Col {...checkboxColSize}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: ROW_HEIGHT
            }}
          >
            <Checkbox
              checked={selected}
              indeterminate={indeterminate}
              onChange={(e) => {
                const id = variant
                  ? `${product[identifier]}-${variant.id}`
                  : product[identifier]

                e.target.checked ? onAdd(id) : onRemove(id)
              }}
              disabled={!selected && disabled ? true : false}
            />
          </div>
        </Col>
      ) : null}
      {shouldRenderHighlightCol(onHighlight) ? (
        <Col {...highlightColSize}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: ROW_HEIGHT
            }}
          >
            {highlightedIndex ? (
              <div
                style={{
                  color: '#FFFFFF',
                  backgroundColor: theme.primary,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '25px',
                  width: '25px',
                  borderRadius: '50%'
                }}
              >
                {highlightedIndex}
              </div>
            ) : (
              <Checkbox
                disabled={disabled}
                indeterminate={indeterminate}
                // checked is replaced with indeterminate because we would like to change the default behavior when indeterminate
                checked={indeterminate}
              />
            )}
          </div>
        </Col>
      ) : null}
      <Col {...productThumbnailColSize}>
        {images.length ? (
          <ProductThumbnail
            imageId={variant?.image_id ?? images[0].image_id}
            images={variant ? [urlToImageMap[variant.image_id]] : images}
            withOverlay={product.hidden ? '/EyeInvisible.svg' : null}
          />
        ) : null}
        {isUnavailable ? (
          <Flex
            position="absolute"
            top={0}
            right={0}
            bottom={0}
            left={0}
            alignItems="center"
            justifyContent="center"
            bg="rgba(255,255,255, 0.5)"
          >
            <StatusTag label={t('Unavailable')} bg="black" />
          </Flex>
        ) : null}
      </Col>
      {variant ? (
        <Col {...productThumbnailColSize} />
      ) : (
        <Col {...productThumbnailColSize} style={unavailableStyle}>
          {images.length > 1 && images[1].image_id ? (
            <ProductThumbnail imageId={images[1].image_id} images={images} />
          ) : null}
        </Col>
      )}
      <Col
        {...productNameColSize(
          onAdd,
          onRemove,
          selectType,
          onCreate,
          onDelete,
          onToggleProductDrop
        )}
        style={unavailableStyle}
      >
        <div
          style={{
            height: ROW_HEIGHT,
            cursor: onHighlight ? 'pointer' : 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <p
            css={css`
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              margin: 0;
              margin-left: 10px;
            `}
          >
            <Flex alignItems="center">
              {nff.cms_show_product_detail && (
                <Tooltip
                  style={{ maxWidth: '350px' }}
                  title={
                    <div>
                      <div>
                        {`${t('External ID')}: ${
                          variant ? variant.unit_ext_id : product.product_ext_id
                        }`}
                      </div>
                      {urlParams?.store_view_code && (
                        <div>{`${t('Store View Code')}: ${
                          urlParams?.store_view_code
                        }`}</div>
                      )}
                    </div>
                  }
                  placement="right"
                >
                  <InfoCircleOutlined style={{ marginRight: '10px' }} />
                </Tooltip>
              )}
              {variant ? variant.unit_name : product.product_name}
            </Flex>
          </p>
          {addedForReplay && (
            <Box as="p" m="0 0 0 10px" color="gray10" fontSize="10">
              {t('Added for Replay')}
            </Box>
          )}
        </div>
      </Col>

      <Col {...productPriceColSize} style={unavailableStyle}>
        <div
          style={{
            height: ROW_HEIGHT,
            cursor: onHighlight ? 'pointer' : 'auto',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <p
            css={css`
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              margin: 0;
            `}
          >
            {variant
              ? variant.unit_price_string
              : firstProductUnit && firstProductUnit.unit_price_string
              ? firstProductUnit.unit_price_string
              : firstProductUnit
              ? firstProductUnit.unit_price
              : ''}
          </p>
        </div>
      </Col>
      {shouldRenderProductDropCol(onToggleProductDrop) ? (
        <Col {...productDropColSize}>
          <div
            style={{
              height: ROW_HEIGHT,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <Switch
              checked={product.hidden}
              onChange={(checked) => onToggleProductDrop(product, checked)}
              checkedChildren={t('On')}
              unCheckedChildren={t('Off')}
            />
          </div>
        </Col>
      ) : null}
      {shouldRenderAddRemoveProductCol(onAdd, onRemove, selectType) ? (
        <Col {...addRemoveProductColSize}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: ROW_HEIGHT
            }}
          >
            {!(disabled || selected) ? (
              <FWButton
                type="link"
                onClick={() => onAdd(product[identifier])}
                style={{ float: 'right' }}
              >
                {t('Add')}
              </FWButton>
            ) : selected ? (
              <FWButton
                type="link"
                onClick={() => onRemove(product[identifier])}
                style={{ float: 'right', color: 'black' }}
              >
                {t('Remove')}
              </FWButton>
            ) : null}
          </div>
        </Col>
      ) : null}
      {shouldRenderImportCol(onCreate) ? (
        <Col {...importColSize}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: ROW_HEIGHT
            }}
          >
            <FWButton
              type="link"
              onClick={() => onCreate(product[identifier])}
              style={{ float: 'right' }}
            >
              {product.id === '0' ? t('Import') : t('Sync')}
            </FWButton>
          </div>
        </Col>
      ) : null}
      {shouldRenderDeleteCol(onDelete) ? (
        <Col {...deleteColSize(onToggleProductDrop)}>
          <div
            onClick={() => onDelete(product.id)}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: ROW_HEIGHT,
              cursor: 'pointer'
            }}
          >
            <Box
              as="img"
              src="/close_black.svg"
              width="12"
              height="12"
              alt="delete"
            />
          </div>
        </Col>
      ) : null}
      {shouldRenderProductAvailableCol(setAvailability) ? (
        <Col {...productAvailableColSize}>
          <Flex
            height={ROW_HEIGHT}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Dropdown
              placement="bottomLeft"
              trigger={['click']}
              overlay={
                <Menu>
                  <Menu.Item key="instructions">
                    <FWButton
                      style={{ color: `${theme.text}` }}
                      onClick={() => setAvailability(product)}
                      type="link"
                    >
                      {isUnavailable
                        ? t('Mark as available')
                        : t('Mark as unavailable')}
                    </FWButton>
                  </Menu.Item>
                </Menu>
              }
            >
              <FWButton type="link">
                <Box
                  as="img"
                  alt="more menu"
                  src="/more-list-view.svg"
                  transform="rotate(90deg)"
                />
              </FWButton>
            </Dropdown>
          </Flex>
        </Col>
      ) : null}
      {shouldRenderExpandSelectVariantCol(
        variant,
        product,
        collapseUnit,
        expandProducts
      ) && (
        <Col {...expandSelectVariantColSize}>
          <div
            onClick={(e) => {
              e.stopPropagation()
              collapseUnit(product)
            }}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: ROW_HEIGHT,
              cursor: 'pointer'
            }}
          >
            <Box
              as="img"
              src={
                expandProducts.includes(product.id)
                  ? '/arrow_down.svg'
                  : '/arrow_right.svg'
              }
            />
          </div>
        </Col>
      )}
    </Row>
  )
}

export default Product
