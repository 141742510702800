import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit'
import api from '@src/common/utils/api'

import { SLICE_NAME } from './constants'
import { SliceState } from './initState'

export const autoEmbed_fetchPlaylistVideos = createAsyncThunk(
  `${SLICE_NAME}/fetchPlaylistVideos`,
  async (params: {
    playlistId: string
    channelId: string
    businessId: string
  }) => {
    const { data } = await api.get(
      `/bus/${params.businessId}/channels/${params.channelId}/playlists/${params.playlistId}/videos`,
      {
        params: {
          is_archived: false
        }
      }
    )

    return data.videos
  }
)

export const autoEmbed_upsertEmbedConfig = createAsyncThunk(
  `${SLICE_NAME}/upsertEmbedConfig`,
  async (params: {
    channelId: string
    businessId: string
    embedConfig: AutoEmbedConfiguration
  }) => {
    const { data } = await api.post(
      `/bus/${params.businessId}/channels/${params.channelId}/upsert_embedded_component`,
      params.embedConfig
    )

    return data
  }
)

export const autoEmbed_fetchEmbedConfig = createAsyncThunk(
  `${SLICE_NAME}/fetchEmbedConfig`,
  async (params: { channelId: string; businessId: string }) => {
    const { data } = await api.get(
      `/bus/${params.businessId}/channels/${params.channelId}/auto_embed_config`
    )

    return data
  }
)

export const autoEmbed_fetchEmbedStatus = createAsyncThunk(
  `${SLICE_NAME}/fetchEmbedStatus`,
  async (params: { storeUrl: string }) => {
    const { data } = await api.get(
      `/shopify/embed_status?myshopify_domain=${params.storeUrl}`
    )

    return data
  }
)

export const autoEmbedBuilder = (
  builder: ActionReducerMapBuilder<SliceState>
): void => {
  builder
    .addCase(autoEmbed_fetchPlaylistVideos.pending, (state) => {
      state.playlistVideosStatus = 'loading'
      state.playlistVideos = []
    })
    .addCase(autoEmbed_fetchPlaylistVideos.fulfilled, (state, action) => {
      state.playlistVideosStatus = 'success'
      state.playlistVideos = action.payload
    })
    .addCase(autoEmbed_fetchPlaylistVideos.rejected, (state) => {
      state.playlistVideosStatus = 'error'
      state.playlistVideos = []
    })
    .addCase(autoEmbed_upsertEmbedConfig.fulfilled, (state, action) => {
      if (!state.embedConfig) {
        state.embedConfig = [action.payload]

        return
      }

      state.embedConfig = state.embedConfig.map((config) => {
        if (config.id === action.payload.id) {
          return action.payload
        }

        return config
      })
    })
    .addCase(autoEmbed_upsertEmbedConfig.rejected, () => {
      throw new Error('Failed to save embed config')
    })
    .addCase(autoEmbed_fetchEmbedConfig.fulfilled, (state, action) => {
      state.embedConfig = action.payload
    })
    .addCase(autoEmbed_fetchEmbedStatus.fulfilled, (state, action) => {
      state.embedStatus = {
        loading: false,
        status: action.payload.status
      }
    })
    .addCase(autoEmbed_fetchEmbedStatus.pending, (state) => {
      state.embedStatus = {
        loading: true,
        status: null
      }
    })
    .addCase(autoEmbed_fetchEmbedStatus.rejected, (state) => {
      state.embedStatus = {
        loading: false,
        status: 'error'
      }
    })
}
