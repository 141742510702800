/* eslint-disable @typescript-eslint/no-explicit-any */
import isPropValid from '@emotion/is-prop-valid'
import styled from '@emotion/styled'
import { spaces } from '@src/hooks/useSpace'
import theme from '@src/styles/theme'

export interface Styles {
  alignContent?: string
  alignItems?: string
  alignSelf?: string
  animation?: string
  backdropFilter?: string
  backgroundImage?: string
  backgroundPosition?: string
  backgroundSize?: string
  border?: string
  borderCollapse?: string
  borderColor?: string
  borderRadius?: string | number
  borderStyle?: string
  borderTop?: string
  borderRight?: string
  borderBottom?: string
  borderLeft?: string
  borderTopLeftRadius?: string | number
  borderTopRightRadius?: string | number
  borderBottomLeftRadius?: string | number
  borderBottomRightRadius?: string | number
  borderWidth?: string | number
  bottom?: string | number
  boxShadow?: string
  boxSizing?: string
  color?: string
  columnGap?: string | number
  cursor?: string
  display?: string
  filter?: string
  flex?: string | number
  flexDirection?: string
  flexFlow?: string
  flexGrow?: string | number
  flexShrink?: string
  flexWrap?: string
  fontFamily?: string
  fontSize?: string | number
  fontVariant?: string
  fontWeight?: string | number
  gap?: string | number
  gridAutoFlow?: string
  gridAutoColumns?: string
  gridAutoRows?: string
  gridArea?: string
  gridColumn?: string | number
  gridRow?: string | number
  gridTemplateColumns?: string
  gridTemplateRows?: string
  height?: string | number
  justifyContent?: string
  justifySelf?: string
  left?: string | number
  lineClamp?: string
  lineHeight?: string | number
  listStyle?: string
  minHeight?: string | number
  minWidth?: string | number
  maxHeight?: string | number
  maxWidth?: string | number
  objectFit?: string
  opacity?: string | number
  order?: string | number
  outline?: string
  overflow?: string
  overflowX?: string
  overflowY?: string
  overflowWrap?: string
  placeSelf?: string
  pointerEvents?: string
  position?: string
  right?: string | number
  rowGap?: string | number
  textAlign?: string
  textDecoration?: string
  textOverflow?: string
  textShadow?: string
  textTransform?: string
  top?: string | number
  transform?: string
  transition?: string
  userSelect?: string
  variant?: string
  whiteSpace?: string
  width?: string | number
  webkitAppearance?: string
  weight?: string | number
  wordBreak?: string
  wordWrap?: string
  background?: string
  margin?: string | number
  marginTop?: string | number
  marginRight?: string | number
  marginBottom?: string | number
  marginLeft?: string | number
  padding?: string | number
  paddingTop?: string | number
  paddingRight?: string | number
  paddingBottom?: string | number
  paddingLeft?: string | number
  zIndex?: string | number
  bg?: string
  m?: string | number
  mt?: string | number
  mr?: string | number
  mb?: string | number
  ml?: string | number
  my?: string | number
  mx?: string | number
  p?: string | number
  pt?: string | number
  pr?: string | number
  pb?: string | number
  pl?: string | number
  py?: string | number
  px?: string | number
}

const customProps: Styles = {
  bg: 'color',
  m: 'space',
  mt: 'space',
  mr: 'space',
  mb: 'space',
  ml: 'space',
  my: 'string',
  mx: 'string',
  p: 'space',
  pt: 'space',
  pr: 'space',
  pb: 'space',
  pl: 'space',
  py: 'string',
  px: 'string'
}

const cssProps: Styles = {
  alignContent: 'string',
  alignItems: 'string',
  alignSelf: 'string',
  animation: 'string',
  backdropFilter: 'string',
  backgroundImage: 'string',
  backgroundPosition: 'string',
  backgroundSize: 'string',
  border: 'string',
  borderCollapse: 'string',
  borderColor: 'color',
  borderRadius: 'space',
  borderStyle: 'string',
  borderTop: 'space',
  borderRight: 'space',
  borderBottom: 'space',
  borderLeft: 'space',
  borderTopLeftRadius: 'space',
  borderTopRightRadius: 'space',
  borderBottomLeftRadius: 'space',
  borderBottomRightRadius: 'space',
  borderWidth: 'space',
  bottom: 'space',
  boxShadow: 'string',
  boxSizing: 'string',
  color: 'color',
  columnGap: 'space',
  cursor: 'string',
  display: 'string',
  filter: 'string',
  flex: 'string',
  flexDirection: 'string',
  flexFlow: 'string',
  flexGrow: 'string',
  flexShrink: 'string',
  flexWrap: 'string',
  fontFamily: 'string',
  fontSize: 'space',
  fontVariant: 'string',
  fontWeight: 'string',
  gap: 'space',
  gridAutoFlow: 'string',
  gridAutoColumns: 'string',
  gridAutoRows: 'string',
  gridArea: 'string',
  gridColumn: 'string',
  gridRow: 'string',
  gridTemplateColumns: 'string',
  gridTemplateRows: 'string',
  height: 'space',
  justifyContent: 'string',
  justifySelf: 'string',
  left: 'space',
  lineClamp: 'string',
  lineHeight: 'string',
  listStyle: 'string',
  minHeight: 'space',
  minWidth: 'space',
  maxHeight: 'space',
  maxWidth: 'space',
  objectFit: 'string',
  opacity: 'string',
  order: 'string',
  outline: 'string',
  overflow: 'string',
  overflowX: 'string',
  overflowY: 'string',
  overflowWrap: 'string',
  placeSelf: 'string',
  pointerEvents: 'string',
  position: 'string',
  right: 'space',
  rowGap: 'space',
  textAlign: 'string',
  textDecoration: 'string',
  textOverflow: 'string',
  textShadow: 'string',
  textTransform: 'string',
  top: 'space',
  transform: 'string',
  transition: 'string',
  userSelect: 'string',
  whiteSpace: 'string',
  width: 'space',
  webkitAppearance: 'string',
  weight: 'space',
  wordBreak: 'string',
  wordWrap: 'string',
  background: 'color',
  margin: 'space',
  marginTop: 'space',
  marginRight: 'space',
  marginBottom: 'space',
  marginLeft: 'space',
  padding: 'space',
  paddingTop: 'space',
  paddingRight: 'space',
  paddingBottom: 'space',
  paddingLeft: 'space',
  zIndex: 'string'
}

const parseCustomProps = ({
  bg,
  m,
  mt,
  mr,
  mb,
  ml,
  my,
  mx,
  p,
  pt,
  pr,
  pb,
  pl,
  py,
  px,
  ...rest
}: any): any => {
  const css: any = {}
  if (bg) css.background = getColor(bg)
  if (m) css.margin = getSpace(m)
  if (mt) css.marginTop = getSpace(mt)
  if (mr) css.marginRight = getSpace(mr)
  if (mb) css.marginBottom = getSpace(mb)
  if (ml) css.marginLeft = getSpace(ml)
  if (p) css.padding = getSpace(p)
  if (pt) css.paddingTop = getSpace(pt)
  if (pr) css.paddingRight = getSpace(pr)
  if (pb) css.paddingBottom = getSpace(pb)
  if (pl) css.paddingLeft = getSpace(pl)
  if (mx) {
    css.marginRight = getSpace(mx)
    css.marginLeft = getSpace(mx)
  }
  if (my) {
    css.marginTop = getSpace(my)
    css.marginBottom = getSpace(my)
  }
  if (px) {
    css.paddingRight = getSpace(px)
    css.paddingLeft = getSpace(px)
  }
  if (py) {
    css.paddingTop = getSpace(py)
    css.paddingBottom = getSpace(py)
  }

  for (const key in rest) {
    const parsing = cssProps[key]

    if (parsing) {
      if (parsing === 'space') {
        css[key] = getSpace(rest[key])
      } else if (parsing === 'color') {
        css[key] = getColor(rest[key])
      } else if (rest[key]) {
        css[key] = rest[key]
      }
    }
  }

  return css
}

const getSpace = (value) =>
  spaces[value] ? `${spaces[value]}px` : parseAsPx(value)
const parseAsPx = (value) => (!isNaN(value) ? `${value}px` : value)
const getColor = (value) => theme[value] || value

const forwardProps = {
  shouldForwardProp: (prop) => {
    return isPropValid(prop) && !cssProps[prop] && !customProps[prop]
  }
}

export const Box = styled(
  'div',
  forwardProps
)<Styles | Record<string, any>>((props) => ({
  ...parseCustomProps(props)
}))
Box.displayName = 'Box'

export const Flex = styled(
  'div',
  forwardProps
)<Styles | Record<string, any>>((props) => ({
  display: 'flex',
  ...parseCustomProps(props)
}))
Flex.displayName = 'Flex'

export const Grid = styled(
  'div',
  forwardProps
)<Styles | Record<string, any>>((props) => ({
  display: 'grid',
  gap: 10,
  ...parseCustomProps(props)
}))
Grid.displayName = 'Grid'

export const Card = styled(
  'div',
  forwardProps
)<Styles | Record<string, any>>((props) => ({
  width: '100%',
  overflow: 'hidden',
  background: '#fff',
  borderRadius: '12px',
  padding: getSpace('large2'),
  ...parseCustomProps(props)
}))
Card.displayName = 'Card'
