import { map, sumBy } from 'lodash'
import moment from 'moment'

import 'moment-duration-format'

/**
 * Adds in missing reports within a date range.
 * Will only set default number values for those defined in dataInitFields.
 * @param {object} params
 *        {array} data Array of data
 *        {array} dataInitFields Fields to assign default values (number types only)
 *        {string} dateKey Key for the date field
 *        {string} startDate Start date
 *        {string} endDate End date
 * @return {array} Data with missing reports filled in
 */
export const fillMissingDates = ({
  data = [],
  dataInitFields = {},
  dateKey = 'report_date',
  startDate,
  endDate
}) => {
  if (!startDate || !endDate) throw Error('Invalid date')

  const newData = [...data]
  const curr = moment.utc(startDate)
  const end = moment.utc(endDate)
  while (!curr.isSameOrAfter(end)) {
    const currDate = curr.format('YYYY-MM-DD')

    // Add new entry if missing in data
    if (!newData.find((obj) => obj[dateKey] === currDate)) {
      newData.push({
        [dateKey]: currDate,
        ...dataInitFields
      })
    }

    // Increment date
    curr.add(1, 'days')
  }

  return sortByDate({ desc: false, key: dateKey, stats: newData })
}

/**
 * Sorts the array of objects by 'date' field.
 * @param {object} params Params for sorting
 *        {array} stats Stats array of objects
 *        {boolean} desc Descending order
 *        {string} key Key to sort by
 * @return {array} Sorted array
 */
export const sortByDate = ({
  stats = [],
  desc = true,
  key = 'report_date'
}) => {
  const cloned = [...stats]
  if (desc) {
    cloned.sort(
      (a, b) => new Date(b[key]).getTime() - new Date(a[key]).getTime()
    )
  } else {
    cloned.sort(
      (a, b) => new Date(a[key]).getTime() - new Date(b[key]).getTime()
    )
  }

  return cloned
}

/**
 * Returns the top video categories and splits the top X with the rest.
 * @param {array} data Array of data
 * @param {number} num Number of top X videos to return
 * @return {array} Top X videos and others
 */
export const getTopCategories = (data, num) => {
  if (!data) throw Error('Data cannot be empty')
  if (!num) throw Error('Must specify num')

  // Calculate total video views
  const videoViewsTotal = sumBy(data, 'video_views')

  // Separate top videos with others
  let top = data.slice(0, num)
  const others = data.slice(num)
  top.push({
    tag_name: 'others',
    display_name: 'Others',
    video_views: sumBy(others, 'video_views'),
    total_minutes_watched: sumBy(others, 'total_minutes_watched')
  })
  top = map(top, (obj) => {
    return {
      ...obj,
      percentage: obj.video_views / videoViewsTotal
    }
  })

  return top
}

export const convertTimeUnit = (totalSeconds) => {
  const formatUnit = (time, unit) =>
    (moment.duration(time, unit) as any).format('h[h] m[m] s[s]', {
      trim: 'both'
    })
  if (totalSeconds < 60) return formatUnit(totalSeconds, 'seconds')
  if (totalSeconds < 3600)
    return formatUnit((totalSeconds / 60).toFixed(2), 'minutes')
  else return formatUnit((totalSeconds / 3600).toFixed(2), 'hours')
}
/*
  Convert minutes to a time unit
  @param {number} minutes
  @param {string} unit
  @return {string} Converted time unit
*/
export const convertTimeMinute = (minutes, unit) => {
  return (moment.duration(minutes, 'minutes') as any).format(unit, {
    trim: 'both'
  })
}

/**
 * Fill up empty data and add 'other' to indexKey for object data which does not have indexKey
 * @param {array} data Input data which is missing some object.
 * @param {string} indexKey Use to identify missing data
 * @param {string|number} value Use this value to fill up all keys for filled object except at indexKey
 * @param {array} arrKeys An array of all keys.
 *                        This array use to identify missing keys
 *
 */
export const fillUpData = (data, indexKey, value = 0, arrKeys) => {
  if (data.length) {
    const fillKeys = [...arrKeys]
    const filteredData = data.map((d) => {
      const mainValue = d[indexKey]
      if (mainValue && fillKeys.includes(mainValue))
        fillKeys.splice(fillKeys.indexOf(mainValue), 1)
      if (!mainValue || !arrKeys.includes(mainValue))
        return { ...d, [indexKey]: 'other' }
      else return d
    })

    const filledData = fillKeys.map((d) => {
      const dummy = { ...filteredData[0] }
      for (const i in dummy) dummy[i] = value
      dummy[indexKey] = d

      return dummy
    })

    return [...filteredData, ...filledData]
  } else return data
}
/*
  Check if 2 arrays have the same elements regardless of order
  [1, 2] = [2, 1]
*/

export const compareArrays = (a, b) => {
  const hash = {}
  if (a.length !== b.length) return false
  for (let i = 0; i < a.length; i++) {
    if (!hash[a[i]]) hash[a[i]] = 1
    else hash[a[i]] += 1
    if (!hash[b[i]]) hash[b[i]] = -1
    else hash[b[i]] -= 1
  }
  for (const k in hash) {
    if (hash[k] !== 0) return false
  }

  return true
}

export const trimText = (text, maxLength) => {
  if (text.length > maxLength) return `${text.substring(0, maxLength)}...`
  else return text
}
